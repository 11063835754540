import React, { Suspense } from "react";
//import logo from "./logo.svg";
import "./App.css";
import "./common.css";
import Entry from './container/Entry';
import { CircularProgress } from "@mui/material";

class App extends React.Component {
  render() {
  
    return (
      <div className="App">
        <Suspense fallback={<><CircularProgress style={{ color: "grey", margin: "40vh auto" }} /></>}>
          <Entry className="full-height" />
        </Suspense>
     </div>
    );
  }
}

export default App;
