import React, { useState, useEffect } from 'react';
import { Divider } from '@mui/material';
import './Metrics.css';

const UserList = (props) => {

  const [avgCompleteCount, setAvgCompleteCount] = useState([]);
  const [avgUploadCount, setAvgUploadCount] = useState([]);
  const [userCompleted, setUserCompleted] = useState([]);
  const [userUploaded, setUserUploaded] = useState([]);
  const [userUpdated, setUserUpdated] = useState([]);


  useEffect(() => {

    const filteredData = props.userData.filter(entry => entry.Document_Completed > 0);
    const sortedData = [...filteredData].sort((a, b) => b.Document_Completed - a.Document_Completed);
    setUserCompleted(sortedData);

  }, [props.userData]);

  useEffect(() => {

    const filteredData = props.userData.filter(entry => entry.Document_Uploaded_to_Meditech > 0);
    const sortedData = [...filteredData].sort((a, b) => b.Document_Uploaded_to_Meditech - a.Document_Uploaded_to_Meditech);
    setUserUploaded(sortedData);

  }, [props.userData]);

  useEffect(() => {

    const filteredData = props.userData.filter(entry => entry.Document_Updated > 0);
    const sortedData = [...filteredData].sort((a, b) => b.Document_Updated - a.Document_Updated);
    setUserUpdated(sortedData);

  }, [props.userData]);


  useEffect(() => {
    if (props.userData && props.userData.length > 0) {
      const tempUploadCount = props.userData.reduce(
        (sum, item) => sum + (item?.Document_Uploaded_to_Meditech ? item.Document_Uploaded_to_Meditech : 0),
        0
      );
      const tempCompleteCount = props.userData.reduce(
        (sum, item) => sum + (item?.Document_Completed ? item.Document_Completed : 0),
        0
      );

      setAvgCompleteCount((tempCompleteCount / props.userData.length).toFixed(1));
      setAvgUploadCount((tempUploadCount / props.userData.length).toFixed(1));
    }
  }, [props.userData]);


  return (
    <div>
      <div className='average-action-metrics'>
        <p className='title' style={{ textAlign: 'left', padding: '5px 2px' }}>Average Action Metrics</p>
        <div className='perform-metrics-section'>
          <div className='perform-each-metric ' style={{ width: "60%" }}>
            <div className="icon-text">
              <div className="perform-flex-baseline">
                <div className="perform-metrics-numbers">{avgUploadCount}</div>
              </div>
            </div>
            <div className="rp-text-metrics">Doc Uploaded To Meditech</div>
          </div>
          <div className='perform-each-metric ' style={{ width: "40%" }}>
            <div className="icon-text">
              <div className="perform-flex-baseline">
                <div className="perform-metrics-numbers">{avgCompleteCount}</div>
              </div>
            </div>
            <div className="rp-text-metrics">Doc Completed</div>
          </div>
        </div>
      </div>
      <div className='most-active-user'>
      <Divider />
      <p className='title' style={{ textAlign: 'left', padding: '6px 2px' }}>Most Active User</p>

      {/*  Document Updated*/}
      {userUpdated.length > 0 ?
        <div className='flex-space-between' style={{ padding: '10px 4px 6px 4px' }}>
          <div style={{ color: '#aa1572' }}>Document Updated</div>
          <div style={{ color: '#aa1572', textAlign: 'right' }}>Count</div>
        </div> : null}
      {userUpdated && userUpdated.slice(0, 3).map((res) => (
        <div className='flex-space-between' style={{ padding: '4px' }} >
          <div>{res.user_name}</div>
          <div style={{ textAlign: 'right' }}>{res.Document_Updated}</div>
        </div>
      ))}

      {/* Document Uploaded */}
      {userUploaded.length > 0 ?
        <div className='flex-space-between' style={{ padding: '10px 4px 6px 4px' }}>
          <div style={{ color: '#aa1572' }}>Document Uploaded To Meditech</div>
          <div style={{ color: '#aa1572', textAlign: 'right' }}>Count</div>
        </div> : null}
      {userUploaded && userUploaded.slice(0, 3).map((res) => (
        <div className='flex-space-between' style={{ padding: '4px' }} >
          <div>{res.user_name}</div>
          <div style={{ textAlign: 'right' }}>{res.Document_Uploaded_to_Meditech}</div>
        </div>
      ))}

      {/* Document Completed*/}
      {userCompleted.length > 0 ?
        <div className='flex-space-between' style={{ padding: '10px 4px 6px 4px' }}>
          <div style={{ color: '#aa1572' }}>Document Completed</div>
          <div style={{ color: '#aa1572', textAlign: 'right' }}>Count</div>
        </div> : null}

      {userCompleted && userCompleted.slice(0, 3).map((res) => (
        <div className='flex-space-between' style={{ padding: '4px' }} >
          <div>{res.user_name}</div>
          <div style={{ textAlign: 'right' }}>{res.Document_Completed}</div>
        </div>
      ))}</div>
    </div>
  );
};

export default UserList;
