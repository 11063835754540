import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';
import './UserTable.css';


const FilterableTable = (props) => {

  const [tableData, setTableData] = useState();
  const [filteredData, setFilteredData] = useState(props.actionColumn);
  const [enabledAction, setEnabledAction] = useState(props.actionColumn);


  useEffect(() => {
    const enabledAction = props.actionColumn.filter(obj => obj.enabled === true).map(obj => obj.key);
    setEnabledAction(enabledAction);
  }, [props.actionColumn]);

  useEffect(() => {
    if (props.userData.length == 0) {
      return
    }
    const filteredData = props?.userData.filter((item) => {
      // return ((item.user_id.includes(props.search) || props.search === '') || item?.user_name.toLowerCase().includes(props.search.toLowerCase()));
      return (item?.user_name.toLowerCase().includes(props.search.toLowerCase()));
    });

    setTableData(filteredData);
  }, [props.userData, props.search])



  useEffect(() => {
    if (!tableData) {
      return
    }
    const result = {};
    const filteredData = tableData.filter((item) => {
      // return ((item.user_id.includes(props.search) || props.search === '') || item?.user_name.toLowerCase().includes(props.search.toLowerCase()));
      return (item?.user_name.toLowerCase().includes(props.search.toLowerCase()));
    });

    enabledAction.forEach((action) => {
      result[action] = filteredData.reduce((acc, obj) => acc + (obj[action] || 0), 0);
    });

    const updatedList = props.actionColumn.map(item => {
      if (result.hasOwnProperty(item.key)) {
        return {
          ...item,
          [item.key]: result[item.key]
        };
      }
      return item;
    });
    setFilteredData(updatedList);

  }, [tableData, enabledAction, props.search]);


  const totalActionCount = (data, enabledObject) => {

    return enabledObject.reduce((total, action) => {
      return total + (data[action] || 0);
    }, 0);
  };

  const getTotalCount = (data1, data2) => {
    let totalCount = 0;

    data1.forEach(item => {
      if (data2.includes(item.key)) {
        totalCount += item[item.key];
      }
    });

    return totalCount;
  };

  const handleChange = (event) => {
    props.setSearch(event.target.value);
  };

  return (
    <div>
      <TableContainer component={Paper} className='user-table-container'>
        <Table stickyHeader>
          <TableHead className="thBorder">
            <TableRow>
              <TableCell className='action-header' style={{ textAlign: 'left' }}>
                <TextField
                  variant="standard"
                  style={{ width: "160px" }}
                  className='table-user-search'
                  placeholder="Search "
                  name="search_user"
                  autoComplete="off"
                  value={props.search}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </TableCell>
              {props.actionColumn && props.actionColumn.filter(data => data.enabled == true).map(res => (
                <TableCell className='action-header' style={{ textAlign: 'center' }}>
                  <div>{res?.label} </div>
                </TableCell>
              ))}
              <TableCell className='action-header' style={{ textAlign: 'center' }}>Total Count</TableCell>
            </TableRow>
            <TableRow className='table-sticky-header' >
              <TableCell className='action-header' style={{ textAlign: 'left' }}>Total</TableCell>
              {filteredData && filteredData.filter(res => enabledAction.includes(res.key)).map(filteredRes => (
                <TableCell key={filteredRes.key} className='action-header' style={{ textAlign: 'center' }}>
                  <div>{filteredRes[filteredRes.key]}</div>
                </TableCell>
              ))}
              <TableCell className='action-header' style={{ textAlign: 'center' }}>{getTotalCount(filteredData && filteredData.filter(obj => obj.enabled === true), enabledAction)}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData && tableData.map(res => (
              <TableRow>
                <TableCell className='action-cell' >
                  <div>{res.user_name}</div>
                  {/* <div style={{fontSize:'10px',color:"#a2a0a0"}}>{res.user_id}</div> */}
                </TableCell>
                {enabledAction.map((row) => (
                  <TableCell className='action-cell' style={{ textAlign: 'center' }}><div>{res[row] ? res[row] : 0}</div></TableCell>
                ))}
                <TableCell className='action-cell' style={{ textAlign: 'center' }}><div>{totalActionCount(res, enabledAction)}</div> </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default FilterableTable;
