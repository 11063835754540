import React, { useEffect, useState, useRef } from "react";
import * as c from '../../utils/constants/constants';
import Table from './UserTable/UserTable';
import Filter from './Filter/Filter';
import { CircularProgress, Divider } from "@mui/material";
import api from '../../utils/API/api';
import Metrics from './Metrics/Metrics';
import { GetDefaultDate, ConvertLocalToUtc, GetCurrentDateAndTime, FormatDate } from "commonFunctions/commonFunctions";
import ReactToPrint from "react-to-print";
import { Print } from '@mui/icons-material';
import Mh_logo from '../../utils/images/Mh_logo.png';
import rd_logo from '../../utils/images/rd_logo.svg';
import rd_name_nav from '../../utils/images/rd_name_nav.svg';
import ExportUser from "./ExportUserPerformance/ExportUserPerformance";
import './UserPerformance.css';


const data = [
  {
    label: 'Doc Split',
    key: 'Document_Splitted',
    enabled: false,
    Document_Splitted: 0
  },
  {
    label: 'Doc Previewed',
    key: 'Document_Previewed',
    enabled: false,
    Document_Previewed: 0
  },
  {
    label: 'Doc Viewed',
    key: 'Document_Viewed',
    enabled: false,
    Document_Viewed: 0
  },
  {
    label: 'Doc Updated',
    key: 'Document_Updated',
    enabled: true,
    Document_Updated: 0
  },
  {
    label: 'Doc Uploaded to Meditech',
    key: 'Document_Uploaded_to_Meditech',
    enabled: true,
    Document_Uploaded_to_Meditech: 0
  },
  {
    label: 'Doc Marked as Completed',
    key: 'Document_Marked_as_Completed',
    enabled: false,
    Document_Marked_as_Completed: 0
  },
  {
    label: 'Doc Completed',
    key: 'Document_Completed',
    enabled: true,
    Document_Completed: 0
  },
  {
    label: 'User Added',
    key: 'User_Added',
    enabled: false,
    User_Added: 0
  },
  {
    label: 'User updated',
    key: 'User_Updated',
    enabled: false,
    User_Updated: 0
  },
]

const UserPerformance = (props) => {

  let defaultUserPerformance = GetDefaultDate({ duration: 7, type: "days", format: "MM-DD-YYYY HH:mm:ss" });

  const [actionColumn, setActionColumn] = useState(data)
  const [userData, setUserData] = useState([])
  const [filter, setFilter] = useState({ from_date: defaultUserPerformance.fromDate, to_date: defaultUserPerformance.toDate })
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState('');
  const componentRef = useRef(null);
  const csvLinkEl = useRef(null);


  const handleSummit = () => {
    getUserData()
  }

  useEffect(() => {
    getUserData();
  }, [])

  const handleChange = (event, value) => {
    let col = typeof event == "string" ? event : event.target.name;
    value = value || event.target.value;
    setFilter(prevState => ({
      ...prevState,
      [col]: (col === "from_date" || col === "to_date") && value === "" ? prevState.filter[col] : value
    }));

  }

  const getUserData = () => {
    let transformedData = []
    setLoader(true)
    let query = {
      from_date: ConvertLocalToUtc({ date: filter.from_date, format: 'MM-DD-YYYY HH:mm:ss', startOf: 'day' }),
      to_date: ConvertLocalToUtc({ date: filter.to_date, format: 'MM-DD-YYYY HH:mm:ss', endOf: 'day' })
    }

    api.getUserPerformance(query)
      .then(res => {
        let { data } = res.data || {}
        setLoader(false);

        Object.keys(data[0]).forEach(key => {
          const temp = {
            "user_id": key,
            ...data[0][key]
          };
          transformedData.push(temp);
        });
        const sortedData = transformedData.sort((a, b) => a.user_name.localeCompare(b.user_name));
        setUserData(sortedData)
      })
      .catch(error => {
        console.error('Error during user Performance report:', error);
      });
  }

  return <>
    <div style={{ marginTop: '70px' }}>
      <div style={{ textAlign: 'left', padding: '10px' }}>
        <div className="page-title">User Performance</div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Filter
            handleSummit={handleSummit}
            actionColumn={actionColumn}
            setActionColumn={setActionColumn}
            filter={filter}
            handleChange={handleChange}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div >
              {userData.length > 1 ?
                <ExportUser
                  userData={userData}
                  actionColumn={actionColumn}
                  filter={filter}
                  getUserData={() => csvLinkEl.current.link.click()}
                  csvLinkEl={csvLinkEl}
                  search={search}
                /> : null}
            </div>
            <div style={{ paddingLeft: '14px' }}>
              <ReactToPrint
                trigger={() => {
                  return (
                    <a href="#" style={{ textDecoration: "none" }}>
                      <div className="rp-print-section">
                        <Print style={{ marginRight: "5px" }} />
                        <div className="rp-print-button">Print</div>
                      </div>
                    </a>
                  );
                }}
                content={() => componentRef.current}
              />
            </div>
          </div>
        </div>
      </div>
      {loader ? <CircularProgress style={{ color: "grey", margin: "40vh auto" }} /> :
        <div ref={componentRef}>
          {/*=============PRINT VIEW===============*/}
          <div className="perform-print-content">
            <div className="perform-print-header">
              <div className="flex-style">
                <img
                  style={{ width: "30px", marginRight: "3px" }}
                  src={rd_logo}
                  alt="rd_logo"
                />
                <img
                  className="rd-nav"
                  style={{ width: "90px" }}
                  src={rd_name_nav}
                  alt="rd_name"
                />
              </div>
              <img
                className="rd-nav"
                style={{ width: "100px" }}
                src={Mh_logo}
                alt="mh-logo"
              />
            </div>
            <div className="perform-flex">
              <div className="titlebox">
                <p className="page-label">{`User Performance Report - ${FormatDate({ date: filter.from_date, format: "MM/DD/YYYY" })} to ${FormatDate({ date: filter.to_date, format: "MM/DD/YYYY" })}`}</p>
                <div className="perform-print-name">
                  <div>Generated By: {localStorage.getItem(c.USER_NAME)}. </div>
                  <div>Date/Time:{" "}{GetCurrentDateAndTime("MM/DD/YYYY, hh:mm A")}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="userperformance-body">
            <div className="metrics-body">
              <div><Metrics userData={userData} /></div>
            </div>
            <Divider orientation="vertical" className="divider-align" />
            <div style={{ flex: 3, padding: " 0px 10px" }} >

              {userData &&
                <div className="userTable">
                  <Table
                    actionColumn={actionColumn}
                    userData={userData}
                    search={search}
                    setSearch={setSearch}
                  />
                </div>}
            </div>
          </div>
        </div>}
    </div>

  </>
}
export default UserPerformance
